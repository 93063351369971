import React, { FC, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Location } from 'history';
import { setLocale } from 'yup';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

import config from 'config';
import { LazyLoad } from 'components';

import { Router } from './Router';
import './styles.css';

setLocale({
  mixed: {
    required: 'This field is required'
  },
  string: {
    email: 'Invalid email'
  }
});

interface AppProps {
  location: Location;
}

export const App: FC<AppProps> = ({ location }) => (
  <div className="App">
    <Helmet {...config.seo} />

    <Suspense fallback={<LazyLoad />}>
      <Router location={location} />
    </Suspense>

    <ToastContainer />
  </div>
);
