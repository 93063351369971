import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Button, PageContainer } from 'components';
import { withAuth } from 'utils/withAuth';

const NotFound: FC = () => (
  <PageContainer>
    <Row className="justify-content-md-center">
      <Col md="6" lg="4">
        <h1 className="mb-4">Page not found</h1>

        <Link to="/">
          <Button color="primary">Back to overview</Button>
        </Link>
      </Col>
    </Row>
  </PageContainer>
);

export default withAuth(NotFound);
