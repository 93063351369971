import React, { FC, forwardRef, ReactNode } from 'react';
import { Field } from 'formik';
import { Label, FormFeedback, Input as RSInput } from 'reactstrap';
import { isValid } from 'date-fns';

import { format } from 'utils/helpers';

export interface InputProps {
  as?: any;
  className?: string;
  disabled?: boolean;
  label?: string;
  id?: string;
  invalid?: boolean;
  onBlur?: any;
  onChange?: any;
  placeholder?: string;
  inputWrapper?(children: ReactNode): ReactNode;
  name?: string;
  multiple?: boolean;
  rows?: number;
  ref?: any;
  step?: string;
  type?: string;
  value?: string;
}

export const Input: FC<InputProps> = forwardRef(
  ({ label, inputWrapper, ...props }, ref) => {
    const Component = props.as || RSInput;

    return (
      <Field name={props.name}>
        {({ field, meta, form: { setFieldValue, setFieldTouched } }) => {
          const input = (
            <Component
              {...field}
              {...(props.type === 'checkbox' ? { checked: !!field.value } : {})}
              {...(props.type === 'date'
                ? {
                    value: isValid(new Date(field.value))
                      ? format(new Date(field.value), 'yyyy-MM-dd')
                      : ''
                  }
                : {})}
              id={field.name}
              innerRef={ref}
              {...props}
              invalid={!!meta.touched && !!meta.error}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          );

          return (
            <div>
              {!!label && <Label for={field.name}>{label}</Label>}
              {inputWrapper ? inputWrapper(input) : input}
              {meta.touched && meta.error && typeof meta.error === 'string' && (
                <FormFeedback>{meta.error}</FormFeedback>
              )}
            </div>
          );
        }}
      </Field>
    );
  }
);
