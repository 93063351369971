import React, { FC, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { get } from 'lodash';

import config from 'config';

interface LocationMapProps {
  onLoadMap?(map: google.maps.Map): void;
  libraries?: string[];
  location: any;
}

const isValidLocation = (location: any) =>
  get(location, 'latitude') && get(location, 'longitude');

export const LocationMap: FC<LocationMapProps> = ({
  libraries,
  location,
  onLoadMap: onLoadMapProp
}) => {
  const mapRef = useRef<any>();
  const { isLoaded: loadedScript } = useLoadScript({
    googleMapsApiKey: config.googleApiKey,
    libraries
  });

  const onLoadMap = (map) => {
    mapRef.current = map;

    if (onLoadMapProp) {
      onLoadMapProp(map);
    }
  };

  useEffect(() => {
    if (isValidLocation(location) && mapRef.current) {
      mapRef.current.panTo(
        new window.google.maps.LatLng(location.latitude, location.longitude)
      );
      mapRef.current.setZoom(15);
    }
  }, [location]);

  if (!loadedScript) {
    return <div />;
  }

  return (
    <GoogleMap
      id="location-map"
      onLoad={onLoadMap}
      mapContainerStyle={{
        borderRadius: 4,
        overflow: 'hidden',
        height: 40,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1
      }}
    />
  );
};
