import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { Navigation } from 'components';
import { useAccount } from 'hooks';

const getDisplayName = (Component) =>
  Component.displayName || Component.name || 'Component';

const loggedOutPaths = ['/login', '/forgot-password', '/set-password'];

export function withAuth(Component: any) {
  const AuthComponent = ({ location, ...props }) => {
    const { isLoggedIn, hasLoaded } = useAccount();

    if (!hasLoaded) {
      return (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <Spinner color="primary" />
        </div>
      );
    }
    if (isLoggedIn && loggedOutPaths.includes(location.pathname)) {
      return <Redirect to="/" />;
    }
    if (!isLoggedIn && !loggedOutPaths.includes(location.pathname)) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        {isLoggedIn && <Navigation />}
        <Component location={location} {...props} />
      </>
    );
  };

  if (Component) {
    hoistNonReactStatics(AuthComponent, Component);
    AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;
  }

  return AuthComponent;
}
