export default {
  title: 'Studio overview',
  studioLabel: 'Studio name',
  studioPlaceholder: 'Enter the name of your studio',
  studioWebsiteLabel: 'Website',
  studioWebsiteDescription:
    'Your logo on the member site will link to your website',
  studioWebsitePlaceholder: 'https://example.com',
  addressLabel: 'Address',
  addressPlaceholder: 'Search: e.g. 2270 Beaver Road',
  postalCodeLabel: 'Zip code',
  postalCodePlaceholder: '20720',
  houseNumberLabel: 'House number',
  houseNumberPlaceholder: '2270',
  streetLabel: 'Street',
  streetPlaceholder: 'Beaver Road',
  cityLabel: 'City',
  cityPlaceholder: 'Landover',
  houseNumberAdditionLabel: 'Address 2',
  houseNumberAdditionPlaceholder: '',
  openingHoursLabel: 'Opening hours',
  colDay: 'Day',
  colOpen: 'Open',
  colFrom: 'From',
  colTo: 'To',
  onSuccess: 'Saved successfully!',
  incomplete: 'Incomplete address',
  required: 'This field is required'
};
