import React, { FC } from 'react';
import { History, Location } from 'history';
import { get, capitalize } from 'lodash';
import { Badge, Table } from 'reactstrap';
import useSWR from 'swr';
import qs from 'query-string';

import { Status } from 'components';
import { useParams, usePagination } from 'hooks';

interface StudioStaffOverviewProps {
  history: History;
  location: Location;
  params: any;
}

const formatRoles = (roles?: string[]) => {
  if (Array.isArray(roles) && roles.length) {
    roles = roles.filter((role) => role !== 'teacher');
    if (roles.length) {
      return roles.map((role) => capitalize(role.replace('-', ' '))).join(', ');
    }
  }

  return '–';
};

export const StudioStaffOverview: FC<StudioStaffOverviewProps> = ({
  history,
  location,
  params: extraParams
}) => {
  const paramProps = useParams({
    pagination: true,
    location,
    history
  });
  const { params } = paramProps;

  const { data, error } = useSWR(
    `/accounts?${qs.stringify({
      ...params,
      ...extraParams
    })}`
  );
  const accounts = get(data, 'accounts');

  const pagination = usePagination({
    ...paramProps,
    total: data?.count
  });

  return (
    <Status loading={!data && !error} error={error}>
      {!accounts?.length ? (
        <p>No staff found.</p>
      ) : (
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Permissions</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(
              ({
                id,
                firstName,
                lastName,
                memberNumber,
                roles,
                email,
                phone,
                isActive
              }) => (
                <tr key={id}>
                  <td>{memberNumber}</td>
                  <td>
                    {firstName} {lastName}
                  </td>
                  <td>{formatRoles(roles)}</td>
                  <td>
                    <a href={`mailto:${email}`}>{email || '–'}</a>
                  </td>
                  <td>{phone ? <a href={`tel:${phone}`}>{phone}</a> : '–'}</td>
                  <td>
                    {isActive ? (
                      <Badge color="success">Active</Badge>
                    ) : (
                      <Badge color="danger">Inactive</Badge>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}

      {pagination}
    </Status>
  );
};
