import { format as formatDateFnsTz, utcToZonedTime } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { get } from 'lodash';

import config from 'config';

// Return error message from response
export const getError = (err) => {
  const res = err.response;

  let error: string = get(
    res,
    'data.fault.message',
    get(
      res,
      'data.error.message',
      String(err) || 'An unknown error has occured.'
    )
  );

  if (error === 'Error: Network Error') {
    error = 'Could not connect to the server!';
  }

  return error;
};

export const format = (date: Date | string, dateFormat: string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (!isValid(date)) {
    return '–';
  }

  return formatDateFnsTz(utcToZonedTime(date, config.timeZone), dateFormat, {
    timeZone: config.timeZone
  });
};

interface FormatPriceOptions {
  currencySign?: boolean;
  spaceAfterCurrency?: boolean;
}

export const formatPrice = (
  price: string | number,
  options?: FormatPriceOptions
) => {
  const { currencySign, spaceAfterCurrency } = options || { currencySign: '$' };

  if (typeof price === 'number') {
    price = `${price}`;
  }

  const formatted = (parseFloat(price) / 100.0).toFixed(2);
  const isNegative = parseFloat(price) < 0;

  return `${
    currencySign
      ? `${isNegative ? `-${spaceAfterCurrency ? ' ' : ''}` : ''}$${
          spaceAfterCurrency ? ' ' : ''
        }`
      : ''
  }${isNegative && currencySign ? formatted.slice(1) : formatted}`;
};
