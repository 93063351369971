import React, { FC, useState, useRef, useEffect, useMemo } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { get, find, includes } from 'lodash';
import { ErrorMessage } from 'formik';
import { Label, Input as StyledInput, Col, Row } from 'reactstrap';

import { InputProps } from '../Input';
import { LocationMap } from '../LocationMap';

import messages from './messages';

const AUTOCOMPLETE_FIELDS = [
  'geometry.location',
  'address_components',
  'formatted_address'
];
const GOOGLE_MAPS_LIBRARIES = ['places'];

const getPlaceField = (place, fields: string | string[]) => {
  let field = fields;

  if (Array.isArray(fields)) {
    [field] = fields;
  }

  const value = find(place.address_components, (component) =>
    includes(component.types, field)
  );

  if (value) {
    return value;
  }

  if (Array.isArray(fields) && fields.length > 1) {
    fields.shift();
    return getPlaceField(place, fields);
  }

  return { long_name: '', short_name: '' };
};

const getAddressParts = (address: string) => {
  const addressParts = address.split(',');
  let addressPart1 = '';
  let addressPart2 = '';
  let addressPart3 = '';

  if (addressParts.length > 0) {
    if (addressParts.length < 3) {
      [addressPart1] = addressParts;

      if (addressParts.length > 1) {
        [, addressPart2] = addressParts;
      }
    } else {
      addressPart3 = (addressParts as any).pop();
      addressPart2 = (addressParts as any).pop();
      addressPart1 = addressParts.join(',');
    }
  }

  return [addressPart1, addressPart2, addressPart3];
};

interface LocationInputProps {
  externalMap?: boolean;
  disabled?: boolean;
  inputField?: FC<InputProps>;
  invalid?: boolean;
  onBlur?(e: any): void;
  onChange?(value: any): void;
  name?: string;
  map?: any;
  loaded?: boolean;
  mapLoaded?: boolean;
  setLocationLabel?(label: any): void;
  setPosition?(coords?: any): void;
  setFieldValue?: any;
  setFieldTouched?: any;
  value?: any;
}

export const LocationInput: FC<LocationInputProps> = ({
  externalMap,
  disabled,
  inputField,
  invalid,
  name: nameProp,
  onBlur,
  onChange,
  map: mapProp,
  loaded: loadedProp,
  mapLoaded: mapLoadedProp,
  setLocationLabel,
  setPosition,
  setFieldValue,
  setFieldTouched,
  value = {}
}) => {
  const autocomplete = useRef<any>();
  let initialValue = useRef<any>(value).current;
  const firstChange = useRef<boolean>(true);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [address, setAddress] = useState('');
  const [hasAddressChanged, setAddressChanged] = useState(false);
  const mapRef = useRef<any>();
  const addressRef = useRef<any>(null);
  const map = mapProp || mapRef;
  const name = nameProp as any;

  let handleChange;

  if (setFieldValue) {
    handleChange = (v) => {
      setFieldValue(name, v);
    };
  } else if (onChange) {
    handleChange = onChange;
  }

  let handleBlur;

  if (setFieldTouched) {
    handleBlur = () => {
      setFieldTouched(name, true);
    };
  } else if (onBlur) {
    handleBlur = onBlur;
  }

  useEffect(() => {
    initialValue = value;
  }, [value]);

  // Override google maps autocomplete attr (https://stackoverflow.com/a/49161445)
  useEffect(() => {
    if (addressRef.current && typeof window.MutationObserver !== 'undefined') {
      addressRef.current.setAttribute('autocomplete', 'new-address');

      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        addressRef.current.setAttribute('autocomplete', 'new-address');
      });

      observerHack.observe(addressRef.current, {
        attributes: true,
        attributeFilter: ['autocomplete']
      });
    }
  }, [mapLoaded, mapLoadedProp]);

  const onLoadMap = (ref) => {
    mapRef.current = ref;
    setMapLoaded(true);
  };

  const onPlaceChanged = () => {
    if (autocomplete.current) {
      const place = autocomplete.current.getPlace();
      const coords = [
        place.geometry.location.lat(),
        place.geometry.location.lng()
      ];

      if (map.current && setPosition) {
        setPosition(place.geometry.location);
        map.current.panTo(place.geometry.location);
        map.current.setZoom(17);
      }

      const street = getPlaceField(place, ['route', 'establishment']);
      const houseNumber = getPlaceField(place, 'street_number');
      const zipCode = getPlaceField(place, 'postal_code');
      const city = getPlaceField(place, 'locality');
      const state = getPlaceField(place, 'administrative_area_level_1');
      const country = getPlaceField(place, 'country');

      const addressParts = getAddressParts(address);

      const locationLabels: string[] = [];
      let houseNumberAddition = '';

      if (street.long_name || houseNumber.long_name) {
        locationLabels.push(
          `${
            street.long_name && houseNumber.long_name
              ? `${houseNumber.long_name} `
              : ''
          }${street.long_name}`
        );

        if (
          houseNumber.long_name &&
          addressParts[0].length > locationLabels[0].length
        ) {
          houseNumberAddition = addressParts[0]
            .substr(locationLabels[0].length)
            .trim();

          if (houseNumberAddition.startsWith('-')) {
            houseNumberAddition = houseNumberAddition.substr(1);
          }

          locationLabels[0] = addressParts[0].trim();
        }
      }
      if (city.long_name) {
        locationLabels.push(city.long_name);
      }
      if (state.short_name) {
        locationLabels.push(state.short_name);
      }
      if (country.long_name) {
        locationLabels.push(country.long_name);
      }

      handleChange({
        latitude:
          initialValue.latitude && firstChange.current
            ? initialValue.latitude
            : coords[0],
        longitude:
          initialValue.longitude && firstChange.current
            ? initialValue.longitude
            : coords[1],
        street: street.long_name || '',
        houseNumber: houseNumber.long_name || '',
        houseNumberAddition:
          houseNumberAddition ||
          (houseNumber.long_name !== value.houseNumber &&
            value.houseNumber &&
            zipCode.long_name !== value.zipCode &&
            value.zipCode)
            ? houseNumberAddition
            : value.houseNumberAddition,
        city: city.long_name || '',
        state: state.short_name || '',
        zipCode: zipCode.long_name || '',
        country: country.short_name || ''
      });

      firstChange.current = false;

      setAddress(locationLabels.join(', '));
      setAddressChanged(true);

      if (setLocationLabel) {
        setLocationLabel(locationLabels);
      }
    }
  };

  const loadAutocomplete = () => {
    if (initialValue.street && initialValue.city && autocomplete.current) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      const request = {
        input: `${initialValue.street}${
          initialValue.houseNumber ? ` ${initialValue.houseNumber}` : ''
        }, ${initialValue.city}`
      };

      autocompleteService.getPlacePredictions(request, (predictionsArr) => {
        const placeRequest = { placeId: predictionsArr[0].place_id };
        const placeService = new window.google.maps.places.PlacesService(
          map.current
        );

        placeService.getDetails(placeRequest, (placeResult) => {
          autocomplete.current.set('place', placeResult);
        });
      });
    }
  };

  const onLoadAutocomplete = (ref) => {
    autocomplete.current = ref;

    loadAutocomplete();
  };

  const onAddressFieldChange = (e) => {
    const { name: n, value: v } = e.target;
    handleChange({
      ...value,
      [n]: v
    });
  };

  const mapComponent = useMemo(() => {
    if (externalMap) {
      return null;
    }

    return (
      <div
        className="d-flex mt-6 position-relative"
        style={{ height: 0, overflow: 'hidden' }}
      >
        <LocationMap
          location={value}
          libraries={GOOGLE_MAPS_LIBRARIES}
          onLoadMap={onLoadMap}
        />
      </div>
    );
  }, [value]);

  return (
    <div>
      {((loadedProp && mapLoadedProp) || mapLoaded) && (
        <Autocomplete
          onLoad={onLoadAutocomplete}
          onPlaceChanged={onPlaceChanged}
          fields={AUTOCOMPLETE_FIELDS}
        >
          <>
            {React.createElement(inputField || (StyledInput as any), {
              id: 'address',
              placeholder: messages.addressPlaceholder,
              innerRef: addressRef,
              invalid,
              value:
                address ||
                (!hasAddressChanged
                  ? initialValue.street &&
                    initialValue.houseNumber &&
                    initialValue.city &&
                    `${initialValue.houseNumber} ${initialValue.street}, ${initialValue.city}`
                  : ''),
              onBlur: handleBlur,
              disabled,
              onChange: (e) => {
                const { target } = e;
                const { value: v } = target as HTMLInputElement;
                setAddress(v);

                if (!v) {
                  handleChange({
                    latitude: '',
                    longitude: '',
                    street: '',
                    houseNumber: '',
                    houseNumberAddition: '',
                    zipCode: '',
                    city: '',
                    state: '',
                    country: ''
                  });
                }
              }
            })}
            <ErrorMessage name={name}>
              {() => (
                <small className="mt-1 text-danger">
                  {messages[!address ? 'required' : 'incomplete']}
                </small>
              )}
            </ErrorMessage>
          </>
        </Autocomplete>
      )}

      {!!value.latitude && (
        <>
          <Row className="mt-3">
            <Col sm={6}>
              <div>
                <Label for="houseNumberAddition">
                  {messages.houseNumberAdditionLabel}
                </Label>
                <StyledInput
                  as={inputField}
                  name="houseNumberAddition"
                  placeholder={messages.houseNumberAdditionPlaceholder}
                  value={value.houseNumberAddition}
                  onChange={onAddressFieldChange}
                  disabled={disabled}
                />
                <ErrorMessage name={name}>
                  {(error) =>
                    get(error, 'houseNumberAddition') ? (
                      <div className="mt-2 text-danger  ">
                        <small>{get(error, 'houseNumberAddition')}</small>
                      </div>
                    ) : null
                  }
                </ErrorMessage>
              </div>
            </Col>
            <Col sm={3}>
              <Label for="houseNumber">{messages.houseNumberLabel}</Label>
              <p>{value.houseNumber || '–'}</p>
              <ErrorMessage name={name}>
                {(error) =>
                  get(error, 'houseNumber') ? (
                    <div className="mt-2 text-danger">
                      <small>{get(error, 'houseNumber')}</small>
                    </div>
                  ) : null
                }
              </ErrorMessage>
            </Col>
            <Col sm={3}>
              <Label for="zipCode">{messages.postalCodeLabel}</Label>
              <p>{value.zipCode || '–'}</p>
              <ErrorMessage name={name}>
                {(error) =>
                  get(error, 'zipCode') ? (
                    <div className="mt-2 text-danger">
                      <small>{get(error, 'zipCode')}</small>
                    </div>
                  ) : null
                }
              </ErrorMessage>
            </Col>
          </Row>
        </>
      )}

      {mapComponent}
    </div>
  );
};
