import React, { FC } from 'react';
import { History, Location } from 'history';
import { get, capitalize } from 'lodash';
import { Badge, Table } from 'reactstrap';
import useSWR from 'swr';
import qs from 'query-string';

import { Status } from 'components';
import { useParams, usePagination } from 'hooks';
import { format, formatPrice } from 'utils/helpers';

interface StudioInvoicesOverviewProps {
  history: History;
  location: Location;
  params: any;
}

export const StudioInvoicesOverview: FC<StudioInvoicesOverviewProps> = ({
  history,
  location,
  params: extraParams
}) => {
  const paramProps = useParams({
    pagination: true,
    location,
    history,
    getInitialParams: (urlParams) => ({
      search: urlParams.search || ''
    })
  });
  const { params } = paramProps;

  const { data, error } = useSWR(
    `/sales?${qs.stringify({
      ...params,
      search: params.search || undefined,
      ...extraParams
    })}`
  );
  const invoices = get(data, 'sales');

  const pagination = usePagination({
    ...paramProps,
    total: data?.count
  });

  return (
    <Status loading={!data && !error} error={error}>
      {!invoices?.length ? (
        <p>No invoices found.</p>
      ) : (
        <Table striped bordered responsive hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Name</th>
              <th>Tax</th>
              <th>Total</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(
              ({
                id,
                name,
                saleItemNames,
                invoiceDate,
                orderNumber,
                total,
                isPaid,
                transactionStatus,
                tax
              }) => (
                <tr
                  key={id}
                  onClick={() => {
                    history.push(`/invoices/${id}`, { params });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{orderNumber}</td>
                  <td>{format(invoiceDate, 'MM-dd-yyyy')}</td>
                  <td>
                    {name ||
                      (saleItemNames && saleItemNames.length > 0
                        ? saleItemNames[0]
                        : 'GymStudio Contract')}
                  </td>
                  <td>{formatPrice(tax)}</td>
                  <td>{formatPrice(total)}</td>
                  <td>
                    {isPaid ? (
                      <Badge color="success">Paid</Badge>
                    ) : (
                      <Badge
                        color={
                          transactionStatus === 'open' || !transactionStatus
                            ? 'info'
                            : transactionStatus === 'pending'
                            ? 'warning'
                            : 'danger'
                        }
                      >
                        {transactionStatus === 'open'
                          ? 'Processing'
                          : !transactionStatus
                          ? 'Scheduled'
                          : capitalize(transactionStatus)}
                      </Badge>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      )}

      {pagination}
    </Status>
  );
};
