import React, { FC } from 'react';
import {
  Pagination as RSPagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { range } from 'lodash';

interface PaginationProps {
  page: number;
  pageSize: number;
  setPage(page: number): void;
  total: number;
}

export const Pagination: FC<PaginationProps> = ({
  page,
  pageSize,
  setPage,
  total
}) => {
  const maxPage = Math.max(1, Math.ceil(total / pageSize));

  return (
    <div className="w-100 d-flex flex-row justify-content-between align-items-center">
      <RSPagination aria-label="Page navigation" className="mb-0">
        <PaginationItem disabled={page === 0}>
          <PaginationLink
            first
            href="#"
            onClick={() => {
              setPage(0);
            }}
          />
        </PaginationItem>
        <PaginationItem disabled={Math.max(0, page - 1) === page}>
          <PaginationLink
            previous
            href="#"
            onClick={() => {
              setPage(Math.max(0, page - 1));
            }}
          />
        </PaginationItem>
        {range(maxPage).map((idx: number) => (
          <PaginationItem key={idx} active={page === idx}>
            <PaginationLink
              href="#"
              onClick={() => {
                setPage(idx);
              }}
            >
              {idx + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={maxPage - 1 === page}>
          <PaginationLink
            next
            href="#"
            onClick={() => {
              setPage(Math.min(maxPage - 1, page + 1));
            }}
          />
        </PaginationItem>
        <PaginationItem disabled={maxPage - 1 === page}>
          <PaginationLink
            last
            href="#"
            onClick={() => {
              setPage(maxPage - 1);
            }}
          />
        </PaginationItem>
      </RSPagination>

      {total > 0 && (
        <p>
          Showing {page * pageSize + 1} –{' '}
          {Math.min(total, (page + 1) * pageSize)} of {total}
        </p>
      )}
    </div>
  );
};
