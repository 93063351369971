import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSWR from 'swr';

import config from 'config';
import { UserContext } from 'providers';
import { getError } from 'utils/helpers';

export const useAccount = () => {
  const {
    user: userData,
    dispatch,
    isLoggedIn,
    hasLoaded
  } = useContext(UserContext);
  const [called, setCalled] = useState(false);
  const { data, error, isValidating, mutate } = useSWR('/session', {
    shouldRetryOnError: false,
    onSuccess: () => {
      setCalled(true);
    }
  });

  useEffect(() => {
    if (!data && (error || (called && !isValidating))) {
      dispatch({ type: 'error' });
    } else if (data && !error) {
      dispatch({ type: 'success', user: data?.user });
    }
  }, [error, isValidating, called, data]);

  const login = async (values: { email: string; password: string }) => {
    try {
      const res = await axios.post('/login', values);

      const { user } = res.data;

      await mutate(user, false);
      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  const forgotPassword = async (values: { email: string }) => {
    try {
      await axios.post('/forgot-password', values);

      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  const updatePassword = async (values: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      await axios.put(`${config.apiUrl}/change-password`, values);

      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  const setPassword = async (values: { token: string; password: string }) => {
    try {
      const res = await axios.put('/set-password', values);

      const { user } = res.data;

      return user;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  const logout = async () => {
    try {
      await axios.post('/logout');

      await mutate(null, false);
      dispatch({ type: 'error' });
      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  return {
    dispatch,
    user: userData,
    error,
    isLoggedIn,
    hasLoaded,
    login,
    forgotPassword,
    setPassword,
    updatePassword,
    logout
  };
};
