import React, { useMemo } from 'react';

import { Pagination } from 'components';

type Params = Record<string, any>;

interface UsePaginationProps {
  changeParam(name: string, value: any): void;
  params: Params;
  total: number;
}

export const usePagination = ({
  changeParam,
  params,
  total
}: UsePaginationProps) => {
  return useMemo(
    () => (
      <Pagination
        page={params.pageNr}
        pageSize={params.pageSize}
        setPage={(page) => {
          changeParam('pageNr', page);
        }}
        total={total || 0}
      />
    ),
    [params.pageNr, params.pageSize, changeParam, total]
  );
};
