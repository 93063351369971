import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Col, FormGroup, Row, Toast, ToastHeader, ToastBody } from 'reactstrap';
import * as Yup from 'yup';

import { Button, FormikButton, Input, Form, PageContainer } from 'components';
import { useAccount } from 'hooks';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required()
});

export const ForgotPassword: FC = () => {
  const { forgotPassword } = useAccount();
  const [isSuccess, setSuccess] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    const success = await forgotPassword(values);

    if (success) {
      setSuccess(success);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <Row className="justify-content-md-center">
        <Col md="6" lg="4">
          <h1 className="mb-4">Forgot password</h1>

          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <>
                {isSuccess ? (
                  <Toast>
                    <ToastHeader icon="success">Reset email sent</ToastHeader>
                    <ToastBody>
                      We have sent an email with a link to reset your password
                      to {values.email}, if an account exists with this email.
                    </ToastBody>
                  </Toast>
                ) : (
                  <Form>
                    <p>
                      Fill in your email and we will send you a link to reset
                      your password
                    </p>
                    <FormGroup>
                      <Input name="email" label="Email" />
                    </FormGroup>
                    <FormikButton>Request reset link</FormikButton>
                  </Form>
                )}
              </>
            )}
          </Formik>

          <Link className="mt-3 d-block" to="/login">
            <Button color="link" className="px-0">
              Back to login
            </Button>
          </Link>
        </Col>
      </Row>
    </PageContainer>
  );
};
