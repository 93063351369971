import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTransition, animated } from 'react-spring';
import {
  Button as RSButton,
  ButtonProps as RSButtonProps,
  Spinner
} from 'reactstrap';

import './styles.css';

interface ButtonProps extends RSButtonProps {
  loading?: boolean;
  type?: 'button' | 'submit';
}

export const Button: FC<ButtonProps> = ({
  children,
  loading,
  type = 'button',
  ...props
}) => {
  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 210, friction: 20 }
  });

  return (
    <RSButton {...props} className={`${props.className} button`} type={type}>
      {children}
      {transitions.map(
        ({ item, key, props: animationProps }) =>
          item && (
            <animated.div key={key} style={animationProps}>
              <div
                className={`spinner__wrapper bg-${props.color || 'secondary'}`}
              >
                <Spinner size="sm" />
              </div>
            </animated.div>
          )
      )}
    </RSButton>
  );
};

export const FormikButton: FC<ButtonProps> = (props) => {
  const { isSubmitting, isValid } = useFormikContext();

  return (
    <Button
      {...props}
      color={props.color || 'primary'}
      type={props.type || 'submit'}
      disabled={!isValid || isSubmitting}
      loading={isSubmitting}
    />
  );
};
