import React, { createContext, useReducer, FC } from 'react';

interface UserContextProps {
  user: any;
  dispatch: any;
  isLoggedIn: boolean;
  hasLoaded: boolean;
}

const initialState = { isLoggedIn: false, user: undefined, hasLoaded: false };

function reducer(state, action) {
  switch (action.type) {
    case 'error':
      return { ...state, user: undefined, isLoggedIn: false, hasLoaded: true };
    case 'success':
      return { ...state, user: action.user, isLoggedIn: true, hasLoaded: true };
    default:
      return state;
  }
}

export const UserContext = createContext<UserContextProps>({
  ...initialState,
  dispatch: () => {}
});

export const UserProvider: FC = ({ children }) => {
  const [{ user, isLoggedIn, hasLoaded }, dispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <UserContext.Provider value={{ user, dispatch, isLoggedIn, hasLoaded }}>
      {children}
    </UserContext.Provider>
  );
};
