import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';

import config from 'config';
import { App } from 'containers';
import { UserProvider } from 'providers';
import 'styles/main.scss';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.adminApiUrl;

ReactDOM.render(
  <SWRConfig
    value={{
      fetcher: (url) => axios.get(url).then((res) => res.data)
    }}
  >
    <UserProvider>
      <Router>
        <Switch>
          <Route component={App} />
        </Switch>
      </Router>
    </UserProvider>
  </SWRConfig>,
  document.getElementById('root')
);
