import React, { FC } from 'react';
import { Spinner, Toast, ToastHeader, ToastBody } from 'reactstrap';

import { getError } from 'utils/helpers';

interface StatusProps {
  loading: boolean;
  error: any;
}

export const Status: FC<StatusProps> = ({ children, loading, error }) => {
  if (loading) {
    return (
      <div className="d-flex w-100 justify-content-center py-5">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-5">
        <Toast>
          <ToastHeader icon="danger">Error</ToastHeader>
          <ToastBody>{getError(error)}</ToastBody>
        </Toast>
      </div>
    );
  }

  return <div>{children}</div>;
};
