import React, { FC } from 'react';
import { Location } from 'history';
import { Switch, Route } from 'react-router-dom';

import {
  ContractsOverview,
  ContractsSingle,
  StudiosOverview,
  // ForgotPassword,
  InvoicesOverview,
  InvoicesSingle,
  Login,
  NotFound,
  Reports,
  // SetPassword,
  StudiosNew,
  StudiosSingle,
  Settings
} from 'containers';

interface RouterProps {
  location?: Location;
}

export const Router: FC<RouterProps> = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/" component={StudiosOverview} />
    <Route exact path="/studios/new" component={StudiosNew} />
    <Route
      exact
      path="/studios/:id/:tab(info|settings|contract|staff)?"
      component={StudiosSingle}
    />
    <Route exact path="/login" component={Login} />
    {/* <Route exact path="/forgot-password" component={withAuth(ForgotPassword)} /> */}
    {/* <Route exact path="/set-password" component={withAuth(SetPassword)} /> */}
    <Route exact path="/invoices" component={InvoicesOverview} />
    <Route
      exact
      path="/invoices/:id/:tab(settings|history)?"
      component={InvoicesSingle}
    />
    <Route exact path="/contracts" component={ContractsOverview} />
    <Route
      exact
      path="/contracts/:id/:tab(settings|invoices|history)?"
      component={ContractsSingle}
    />
    <Route exact path="/reports" component={Reports} />
    <Route exact path="/settings" component={Settings} />
    <Route component={NotFound} />
  </Switch>
);
