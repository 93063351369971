import React, { FC } from 'react';
import { Formik } from 'formik';
import { History, Location } from 'history';
import { get } from 'lodash';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { Button, FormikButton, Input, Form, PageContainer } from 'components';
import { useAccount } from 'hooks';

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required()
});

interface SetPasswordProps {
  history: History;
  location: Location;
}

export const SetPassword: FC<SetPasswordProps> = ({ history, location }) => {
  const { setPassword } = useAccount();

  const handleSubmit = async (values, { setSubmitting }) => {
    const user = await setPassword({
      ...values,
      token: get(qs.parse(location.search), 'token')
    });

    if (user) {
      toast('Password set successfully, you can now login with your account.', {
        type: 'success'
      });
      history.push(`/login?${qs.stringify({ email: user.email })}`);
    } else {
      setSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <Row className="justify-content-md-center">
        <Col md="6" lg="4">
          <h1 className="mb-4">Set password</h1>

          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <p>Set a password for your account</p>
              <FormGroup>
                <Input name="password" label="Password" type="password" />
              </FormGroup>
              <FormikButton>Set password</FormikButton>
            </Form>
          </Formik>

          <Link className="mt-3 d-block" to="/login">
            <Button color="link" className="px-0">
              Back to login
            </Button>
          </Link>
        </Col>
      </Row>
    </PageContainer>
  );
};
