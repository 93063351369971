import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Location, History } from 'history';
import qs from 'query-string';

const PAGE_SIZE = 20;

type Params = Record<string, any>;

interface UseParamsProps {
  history: History;
  location: Location;
  pagination?: boolean;
  getInitialParams?(urlParams: Params): Params;
}

const updateParams = debounce(
  (params: Params, history: History, pathname: string) => {
    history.replace(
      `${pathname}?${qs.stringify({
        ...params
      })}`
    );
  },
  200
);

export const useParams = ({
  history,
  location,
  pagination,
  getInitialParams
}: UseParamsProps) => {
  const urlParams = (qs.parse(location.search) as Params) || {};
  const [params, setParams] = useState<Params>({
    ...(pagination
      ? { pageNr: parseInt(urlParams?.pageNr, 10) || 0, pageSize: PAGE_SIZE }
      : {}),
    ...(getInitialParams ? getInitialParams(urlParams) : {})
  });
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      updateParams(params, history, location.pathname);
    }
  }, [params]);

  const changeParam = (name, value) => {
    setParams({
      ...params,
      [name]: value
    });
  };

  return { params, changeParam, setParams };
};
