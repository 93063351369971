const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
const adminApiUrl =
  process.env.REACT_APP_ADMIN_API_URL || 'http://localhost:3001/admin';

export default {
  apiUrl,
  adminApiUrl,
  googleApiKey: 'AIzaSyD2aaLH2dLPlCpNEs56fZ9dJ-1zcYqBj3g',
  seo: {
    defaultTitle: 'Superadmin - GymStudio',
    meta: [
      {
        name: 'description',
        content: 'GymStudio Superadmin'
      }
    ]
  },
  timeZone: 'America/New_York',
  languages: [
    { label: 'English', value: 'en' },
    { label: 'Dutch', value: 'nl' }
  ],
  studioCategories: {
    other: 'Studio management',
    yoga: 'Yoga studio software',
    pilates: 'Pilates studio software',
    hitt: 'HIIT studio software',
    dance: 'Dance studio software'
  },
  studioCountries: [
    { label: 'The Netherlands', value: 'NL' },
    { label: 'Belgium', value: 'BE' },
    { label: 'France', value: 'FR' },
    { label: 'Germany', value: 'DE' },
    { label: 'Italy', value: 'IT' },
    { label: 'Spain', value: 'ES' }
  ],
  studioModules: ['app', 'mailchimp', 'payroll']
};
