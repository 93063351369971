import React, { FC } from 'react';
import { Form as FormikForm } from 'formik';
import { Form as RSForm } from 'reactstrap';

export const Form: FC = ({ children, ...props }) => {
  return (
    <RSForm tag={FormikForm} {...props}>
      {children}
    </RSForm>
  );
};
